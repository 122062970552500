import "./index.scss";
import {
  glide,
  Anchors,
  Autoplay,
  Controls,
  LazyLoad,
  Swipe
} from "@olmokit/core/glide";
import "@olmokit/core/glide/bullets";
import "./index.scss";
import Bullspin from "utils/bullspin";

/**
 * Component: SliderHome
 */
export function SliderHome() {
  console.log("SliderHome mounted.");

  return glide(".Slider:", {
    autoplay: 3500
  }).mount({ Swipe, /* Autoplay, */ Anchors, Controls, LazyLoad, Bullspin });
}
