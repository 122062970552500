/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */
import "layouts/main";
import { Header } from "components/Header/Info";
import { Storefinder } from "components/Storefinder";
import { $ } from "@olmokit/dom";
import { SliderHome } from "components/Slider/Home";
import "components/Hamburger";
import "./index.scss";

console.log("Route home/index.js mounted.");

Header();
SliderHome();
const $root = $('.Storefinder:img');
Storefinder($root).init();