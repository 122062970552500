import "@olmokit/core/debug/api";
import "@olmokit/core/grid/index.scss";
import "@olmokit/core/icon/index.scss";
import "@olmokit/core/img";
import "components/Image";
import LazyLoad from "@olmokit/core/lazy";
import "@olmokit/core/reboot/index.scss";
import "@olmokit/core/typography/reset.scss";
import { CookiesBanner } from "@olmokit/core/cookies/banner";
import { Footer } from "components/Footer";
import "utils/btn.scss";
import "utils/cta.scss";
import "utils/fonts.scss";
import "utils/logo.scss";
import "utils/type.scss";
import "./index.scss";

new LazyLoad();
CookiesBanner("cookies_accepted");
Footer();

// utm_source for Salesforce inherited from AP
let url = window.location.href;
let urlUrl = new URL(url);
let params = new URLSearchParams(urlUrl.search.slice(1));
for (let p of params) {
    if(p[0] == "utm_source"){
        localStorage.setItem('utm_source', p[1]);
    }
}