import { $ } from "@olmokit/dom";
import "./animatedGlobe.scss";

export default function ($root) {
  import("zdog").then(({ default: Zdog }) => {
    const ENABLE_X_ROTATION = false;
    const TAU = Zdog.TAU;
    const planetRadius = 47;
    const polesLength = 30;
    const lineThickness = 2;
    const lineColor = "#000";

    // let mouseX = 0;
    // let mouseY = 0;

    let isSpinning = false;
    isSpinning = true;

    const space = new Zdog.Illustration({
      element: $(".globe__anim", $root),
      // dragRotate: true,
    });

    const groupStatic = new Zdog.Group({
      addTo: space,
      translate: { z: 20 },
    });

    const groupGlobe = new Zdog.Anchor({
      addTo: space,
      dragRotate: true,
      rotate: ENABLE_X_ROTATION
        ? { x: TAU * 0.05 }
        : {
            // z: TAU * 0.03
          },
      onDragStart: function () {
        isSpinning = false;
      },
    });

    const sphere = new Zdog.Ellipse({
      addTo: groupStatic,
      diameter: planetRadius * 2,
      stroke: lineThickness,
      color: lineColor,
    });

    const northPole = new Zdog.Shape({
      addTo: groupGlobe,
      path: [
        { y: planetRadius * -1 },
        { y: (planetRadius + polesLength) * -1 },
      ],
      stroke: lineThickness,
      color: "black",
    });

    northPole.copy({
      path: [{ y: planetRadius }, { y: planetRadius + polesLength }],
      color: "black",
    });

    setLatitude(-0.5);
    setLatitude(0);
    setLatitude(0.5);
    setLatitude(6);
    setLongitude(1);
    setLongitude(3);

    function setLongitude(lng) {
      new Zdog.Ellipse({
        addTo: groupGlobe,
        rotate: { y: TAU / lng },
        translate: { x: lng },
        diameter: planetRadius * 2 + 1,
        stroke: lineThickness,
        backface: lineColor,
        color: lineColor,
      });
    }

    function setLatitude(factor) {
      const catetoMinore = planetRadius - planetRadius * Math.abs(factor);
      const ipotenusa = planetRadius;
      // cateto maggiore è uguale al raggio della calotta
      const catetoMaggiore = Math.sqrt(
        Math.pow(ipotenusa, 2) - Math.pow(catetoMinore, 2)
      );

      new Zdog.Ellipse({
        addTo: groupGlobe,
        rotate: { x: TAU / 4.1 },
        translate: { y: planetRadius * factor },
        diameter: factor === 0 ? planetRadius * 2 : catetoMaggiore * 2,
        stroke: lineThickness,
        backface: lineColor,
        color: lineColor,
      });
    }

    /**
     * @see https://zzz.dog/api#dragger
     */
    function animate() {
      // const rotateY = (mouseY / 200) * 0.03;
      if (isSpinning) {
        if (ENABLE_X_ROTATION) {
          groupGlobe.rotate.x += isSpinning ? -0.01 : 0;
        }
        // groupGlobe.rotate.y += isSpinning ? -rotateY : 0;
        groupGlobe.rotate.y += isSpinning ? -0.03 : 0;
      }
      space.updateRenderGraph();
      requestAnimationFrame(animate);
    }

    // function handleMouseMove(event) {
    //   mouseX = event.pageX;
    //   mouseY= event.pageY;
    // }

    // document.addEventListener("mousemove", handleMouseMove);

    animate();
  });
}
