import loadResource from "@olmokit/core/ajax/loadResource";
import { $, on, setDataAttr, getDataAttr } from "@olmokit/dom";
import "@olmokit/core/forms/select/material";
import "@olmokit/core/forms/input/material";
import { getUrlQueryParams } from "@olmokit/utils";
import {
  navigateToMergedParams,
  navigateWithoutUrlParam,
  redirectTo,
} from "@olmokit/browser";
import {
  GMAP_ASYNC_BUNDLE_KEY,
  GMAP_SCRIPT_URL,
  GMAP_USA_BOUNDS,
} from "config/index";
import "./index.scss";

type ResultCoords = { lat: number; lng: number };

type ResultPlace = ResultCoords & { address: string };

type ResultGeolocation = ResultCoords;

// type ResultFreesearch = { query: string };

// type GmapPlace = any;

type SearchaddressOptions = {
  onCoords?: (data: ResultCoords) => void;
  onPlace?: (data: ResultPlace) => void;
  onGeolocation?: (data: ResultGeolocation) => void;
  onSubmit?: (query: string) => void;
  onEmpty?: () => void;
};

/**
 * Searchaddress input component, integrated with Google Maps autocomplete API
 */
export function Searchaddress($root: HTMLElement, options: SearchaddressOptions = {}) {

  const { onCoords, onPlace, onGeolocation, onSubmit, onEmpty } = options;

  let redirectUrl: string | undefined;
  let $wrap: HTMLDivElement | undefined;
  let $input: HTMLInputElement | undefined;
  let $submit: HTMLButtonElement | undefined;
  let $geolocation: HTMLButtonElement | undefined;
  let wasEmpty: boolean | undefined;

  // const GMAPS_AUTOCOMPLETE_RESTRICTIONS = { country: ["us"] };
  const GMAPS_AUTOCOMPLETE_FIELDS = [
    "geometry",
    "name" /* , "formatted_address" */,
  ];

  /**
   * Google Maps API
   * @type {google.maps}
   */
  let gmaps;
  /**
   * Google Maps Auotocomplete instance
   * @type {google.maps.places.Autocomplete}
   */
  let gmapsAutocomplete;
  /**
   * Google Maps Geocoder instance
   * @type {google.maps.Geocoder}
   */
  let gmapsGeocoder;

  /**
   * Init searchaddress component
   */
  function init(prefill) {
    $wrap = $(".Searchaddress:", $root);
    $input = $(".formControl", $wrap);
    $submit = $(".Searchaddress:submit", $root);
    $geolocation = $(".Searchaddress:geolocation", $root);
    redirectUrl = getDataAttr($input, "redirect");

    if (prefill) {
      $input.value = prefill;
    }

    on($input, "keyup", handleKeyup);
    on($submit, "click", handleSubmit);

    if (navigator.geolocation) {
      on($geolocation, "click", handleClickGeolocation);
    } else {
      hideGeolocation();
    }

    deeplinkPrefill();

    loadGmaps();

    return {
      /**
       * @type {HTMLInputElement} The DOM input elemnt
       */
      $input,
    };
  }

  /**
   * Load google maps script asynchrnously
   */
  function loadGmaps() {
    if (!loadResource.isDefined(GMAP_ASYNC_BUNDLE_KEY)) {
      loadResource([GMAP_SCRIPT_URL], GMAP_ASYNC_BUNDLE_KEY, {
        numRetries: 3,
      });
    }

    loadResource.ready([GMAP_ASYNC_BUNDLE_KEY], initGmaps);
  }

  /**
   * Init google maps API
   */
  function initGmaps() {
    gmaps = window["google"].maps;
    gmapsAutocomplete = new gmaps.places.Autocomplete($input);
    gmapsGeocoder = new gmaps.Geocoder();

    // set initial restrict to the greater list of countries.
    // gmapsAutocomplete.setComponentRestrictions(GMAPS_AUTOCOMPLETE_RESTRICTIONS);

    // specify only the data fields that are needed.
    gmapsAutocomplete.setFields(GMAPS_AUTOCOMPLETE_FIELDS);

    // sets the preferred area within which to return Place results. Results are
    // biased towards, but not restricted to, this area.
    gmapsAutocomplete.setBounds(GMAP_USA_BOUNDS);

    // listen for address selection
    gmapsAutocomplete.addListener("place_changed", handlePlaceChanged);
  }

  /**
   * Handle gmap `place_changed` event
   *
   * We might get here that place is not there, because we bind the enter
   * key event to the input in order to re-center the map once the user has
   * searched for a place, then moved away, then wants to re-center it to the
   * input field value previously written.
   */
  function handlePlaceChanged() {
    const place = gmapsAutocomplete.getPlace();
    // const address = encodeURIComponent($input.value);
    let params;

    if (__DEV__) {
      console.log("Searchaddress: place_changed", place);
    }

    dataLayer.push({
      event: "store_locator",
      click_type: "search",
      element_clicked: "place_changed",
    });

    // place.geometry is there when Google Maps finds some coords
    if (place && place.geometry) {
      params = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        address: $input.value,
      };
      if (onCoords) onCoords({ lat: params.lat, lng: params.lng });
      // if (onPlace) onPlace({ ...params, address: place.formatted_address });
      // if (onPlace) onPlace({ ...params, address: place.name });
      if (onPlace) onPlace(params);
    }
    // place.geometry is not there when user entered the name of a Place that
    // was not suggested and pressed the Enter key, or when for external reasons
    // the Place Details request failed.
    else {
      params = { query: $input.value };
      if (onSubmit) onSubmit($input.value);
    }

    // in any case the searched location is considered to be ready
    whenLocationIsReady(params);
  }

  /**
   * Handle search input keyup event
   *
   * @param {KeyboardEvent} event
   */
  function handleKeyup(event) {
    if ($input.value) {
      wasEmpty = false;
      setStatus("ok");
    } else {
      if (!wasEmpty && onEmpty) onEmpty();
    }
    if (event.keyCode == 13) {
      handleSubmit();
      return false;
    }
  }

  /**
   * Hanlde submit action (either from enter key or submit btn click)
   */
  function handleSubmit() {
    if ($input.value) {
      handlePlaceChanged();
      // if (onSubmit) onSubmit($input.value);
    } else {
      setStatus("empty");
    }
  }

  /**
   * Get user's geolocation with HTML5 API
   */
  function handleClickGeolocation() {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        const lat = coords.latitude;
        const lng = coords.longitude;

        navigateWithoutUrlParam("address");

        if (onCoords) onCoords({ lat, lng });
        if (onGeolocation) onGeolocation({ lat, lng });

        if (gmapsGeocoder) {
          gmapsGeocoder.geocode(
            { location: { lat, lng } },
            (results, status) => {
              if (status === "OK" && results && results[0]) {
                $input.value = results[0].formatted_address;
                whenLocationIsReady({ lat, lng, address: $input.value });
              } else {
                whenLocationIsReady({ lat, lng });
                console.warn("Geocoder failed due to: " + status);
              }
            }
          );
        } else {
          whenLocationIsReady({ lat, lng });
        }
      },
      () => {
        setStatus("geolocationfailed");
      }
    );
  }

  /**
   * When location iss ready
   *
   * @param {ResultPlace | ResultGeolocation | ResultFreesearch} data
   */
  function whenLocationIsReady(data) {
    // dataLayer.push({ event: "click store locator" });
    const params = { ...getUrlQueryParams(), ...data };

    // either redirect to another page
    if (redirectUrl) {
      redirectTo(redirectUrl, params);
    }
    // or just update the current query page url query params
    else {
      navigateToMergedParams(params);
    }
  }

  /**
   * Hide geolocation button
   */
  function hideGeolocation() {
    $geolocation.style.display = "none";
  }

  /**
   * Prefill input based on query params
   */
  function deeplinkPrefill() {
    const { address, query } = getUrlQueryParams() as Record<string, string>;
    const value = address || query;

    if (value) {
      $input.value = decodeURIComponent(value);
    }
    wasEmpty = !!value;
  }

  /**
   * Set status
   *
   * @param {"ok" | "empty" | "geolocationfailed"} status
   */
  function setStatus(status) {
    setDataAttr($wrap, "status", status);
  }

  return {
    init,
  };
}
